import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import { jobs, jobs1} from "../jobs";
import Button from "@mui/material/Button";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {useNavigate} from 'react-router-dom';

const Careers = () => {
    const navigate = useNavigate();
    const PropertiesBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
        },
    }));

    const CareersTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));
    const JobBox = styled(Box)(({ theme }) => ({
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        maxWidth: 350,
        backgroundColor: "#fff",
        margin: theme.spacing(0, 2, 0, 0),
        [theme.breakpoints.down("md")]: {
            margin: theme.spacing(2, 0, 2, 0),
        },
    }));

    const InfoBox = styled(Box)(() => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }));

    const navigateToDetails = (id) => {
        //navigate("/job-details/" + id);
    };

    return (
        <Box sx={{ backgroundColor: "#F5FAFE", py: 5 }}>
            <Container>
                <CareersTextBox>
                    <Typography
                        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                    >
                        Careers at Assemblee
                    </Typography>
                    <Typography sx={{ color: "#5A6473", fontSize: "16px", mt: 1 }}>
                        At Assemblee, we believe in fostering a dynamic, diverse, and inclusive workplace where every individual's talents are valued and their potential is nurtured. If you're passionate about making a difference,
                        challenging the status quo, and contributing to innovative solutions, you've come to the right place.
                    </Typography>
                    <Typography
                        sx={{ marginTop: "1em", mb: "1em", color: "#000339", fontSize: "28px", fontWeight: "bold" }}
                    >
                        Current Openings
                    </Typography>
                </CareersTextBox>

                <PropertiesBox>
                    {jobs.map((job) => (
                        <JobBox>
                            <Box sx={{ padding: "1rem", width: "300px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <InfoBox sx={{ marginRight: "0.1em"  }}>
                                        <CalendarMonthIcon/>
                                    </InfoBox>

                                    <InfoBox sx={{ marginRight: "4em"  }}>
                                        <Typography variant="body2">
                                            {job.date}
                                        </Typography>
                                    </InfoBox>

                                    <InfoBox sx={{ marginRight: "0.1em",   }}>
                                        <Box sx={{ backgroundColor: "#0F1B4C", color: "#fff", width: "80px", textAlign: "center"}}>
                                            <Typography variant="caption">
                                                {job.category}
                                            </Typography>
                                        </Box>
                                    </InfoBox>
                                </Box>

                                <Typography variant="h6" sx={{ fontWeight: "700", mt: "1em" }}>
                                    {job.position}
                                </Typography>
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => navigateToDetails(job.id)}
                                >
                                    Read More
                                </Button>
                            </Box>
                        </JobBox>
                    ))}
                </PropertiesBox>
                <PropertiesBox>
                    {jobs1.map((job) => (
                        <JobBox>
                            <Box sx={{ padding: "1rem", width: "300px" }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <InfoBox sx={{ marginRight: "0.1em"  }}>
                                        <CalendarMonthIcon/>
                                    </InfoBox>

                                    <InfoBox sx={{ marginRight: "4em"  }}>
                                        <Typography variant="body2">
                                            {job.date}
                                        </Typography>
                                    </InfoBox>

                                    <InfoBox sx={{ marginRight: "0.1em",   }}>
                                        <Box sx={{ backgroundColor: "#692DEC", color: "#fff", width: "80px", textAlign: "center"}}>
                                            <Typography variant="caption">
                                                {job.category}
                                            </Typography>
                                        </Box>
                                    </InfoBox>
                                </Box>

                                <Typography variant="h6" sx={{ fontWeight: "700", mt: "1em" }}>
                                    {job.position}
                                </Typography>
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardIcon />}
                                    onClick={() => navigateToDetails(job.id)}
                                >
                                    Read More
                                </Button>
                            </Box>
                        </JobBox>
                    ))}
                </PropertiesBox>
            </Container>
        </Box>
    );
};

export default Careers;
