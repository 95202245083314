import Routing from './routes';
import { HashRouter} from "react-router-dom"
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CookieConsent from "react-cookie-consent";

export const lightTheme = {
    palette: {
        primary: {
            main: '#495194',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#fcfcfe',
        },
    }
};

const defaultTheme = createTheme(lightTheme);

function App() {
    return (
        <>
                <HashRouter>
                    <Routing/>
                </HashRouter>
                <CookieConsent
                    location="bottom"
                    buttonText="ACCEPT"
                    declineButtonText={"DENY"}
                    enableDeclineButton
                    flipButtons
                    cookieName="count"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                    overlay
                >
                    We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
                </CookieConsent>
        </>
    );
}

export default App;
