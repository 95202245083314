import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from "../Components/Navbar";
import Home from "../Components/Home";
import Footer from "../Components/Footer";
import Pricing from "../Components/Pricing";
import Faq from "../Components/Faq";
import AboutUs from "../Components/AboutUs";
import HelpCenter from "../Components/HelpCenter";
import Careers from "../Components/Careers";
import Security from "../Components/Security";
import TermsAndConditions from "../Components/TermsAndConditions";
import Blog from "../Components/Blog";
import Signup from "../Components/Signup";
import Privacy from "../Components/Privacy";
import Features from "../Components/Features";
import ForgotPassword from "../Components/ForgotPassword";
import Download from "../Components/Download";
import PasswordReset from "../Components/PasswordReset";

const Routing = () => {
    const [index, setIndex] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/" || location.pathname === "/download") {
            setIndex(false);
        } else {
            setIndex(true);
        }
    }, [location]);

    return (
        <>
            {index && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/help-center" element={<HelpCenter />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/security" element={<Security />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/features" element={<Features />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/download" element={<Download />} />
                <Route path="/password-reset/:id" element={<PasswordReset />} />
                <Route path='/facebook' Component={() => {
                    window.location.href = 'https://www.facebook.com/people/BEETs-Lounge/61557945423210/';
                    return null;
                }}/>
                <Route path='/tiktok' Component={() => {
                    window.location.href = 'https://www.tiktok.com/@beets.lounge?_t=8lGIHwMhZEf&_r=1';
                    return null;
                }}/>
                <Route path='/snapchat' Component={() => {
                    window.location.href = 'https://t.snapchat.com/5Qlx4Trg';
                    return null;
                }}/>
            </Routes>
            <Footer />
        </>
    );
};

export default Routing;
