import Hero from "./Hero";

function Home() {
    return (
        <>
            <Hero />
        </>
    );
}

export default Home;
