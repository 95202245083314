import { styled, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import {useNavigate} from "react-router-dom";

import snapchatIcon from "../media/snapchat.svg";
import instagramIcon from "../media/instagram.svg";
import facebookIcon from "../media/facebook.svg";
import tiktokIcon from "../media/tiktok.svg";

const Footer = () => {

  const navigate = useNavigate();

  const CustomContainer = styled(Container)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    gap: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const IconBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  }));

  const FooterLink = styled("span")(({ theme }) => ({
    fontSize: "16px",
    color: "#7A7A7E",
    fontWeight: "300",
    cursor: "pointer",
    "&:hover": {
      color: "#e7e1e1",
    },
  }));

  const navigateToPage = (page) => {
      navigate(page);
  };

  return (
    <Box sx={{ py: 10}}>
      <CustomContainer>
        <CustomContainer>
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#767694",
                fontWeight: "700",
                mb: 2,
              }}
            >
              What we do
            </Typography>

            <FooterLink onClick={() => navigateToPage("/blog")}>Blog</FooterLink>
            <br />
            {/*<FooterLink onClick={() => navigateToPage("/help-center")}>Help Center</FooterLink>*/}
            {/*<br />*/}
            <FooterLink onClick={() => navigateToPage("/pricing")}>Pricing</FooterLink>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#767694",
                fontWeight: "700",
                mb: 2,
              }}
            >
              Who we are
            </Typography>

            <FooterLink onClick={() => navigateToPage("/about-us")}>About us</FooterLink>
            <br />
            <FooterLink onClick={() => navigateToPage("/careers")}>Careers</FooterLink>
            <br />
            <FooterLink onClick={() => navigateToPage("/privacy")}>Terms & Privacy Policy</FooterLink>
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#767694",
                fontWeight: "700",
                mb: 2,
              }}
            >
              Use Assemblee
            </Typography>

            <FooterLink onClick={() => navigateToPage("/download")}>Android</FooterLink>
            <br />
            <FooterLink onClick={() => navigateToPage("/download")}>iPhone</FooterLink>
            <br />
            {/*<FooterLink onClick={() => navigateToPage("/download")}>Mac/PC</FooterLink>*/}
          </Box>

          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#767694",
                fontWeight: "700",
                mb: 2,
              }}
            >
              Follow us
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                color: "#7A7A7E",
                fontWeight: "500",
                mb: 2,
              }}
            >
              You can find us on all social media platforms.
            </Typography>

            <IconBox>
              <img src={snapchatIcon} alt="snapchatIcon" style={{ cursor: "pointer" }} />
              <img
                src={instagramIcon}
                alt="instagramIcon"
                style={{ cursor: "pointer" }}
              />
              <img
                  src={tiktokIcon}
                  alt="tiktokIcon"
                  style={{ cursor: "pointer" }}
              />
              <img
                  src={facebookIcon}
                  alt="facebookIcon"
                  style={{ cursor: "pointer" }}
              />
            </IconBox>
          </Box>
        </CustomContainer>
      </CustomContainer>
    </Box>
  );
};

export default Footer;
