import { Box, Button, styled, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";

import heroImg2 from "../media/hero.jpg";
import {useNavigate} from "react-router-dom";

const Hero = () => {
    const navigate = useNavigate();
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(5),

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

    const navigateToPage = (page) => {
        navigate(page);
    };


    return (
      <>
          <Navbar />
          <Box sx={{
              backgroundColor: "#789292",
              minHeight: "80vh",
              backgroundImage: 'url(' + heroImg2 + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              boxShadow: "inset 0 0 0 99999px rgba(120, 120, 255, 0.4)",
          }}>

              <Container>
                  <CustomBox>
                      <Box sx={{ flex: "1" }}>
                          <Typography
                              variant="body2"
                              sx={{
                                  fontSize: "18px",
                                  color: "#fff",
                                  fontWeight: "500",
                                  mt: 5,
                                  mb: 4,
                              }}
                          >
                              Welcome to the Assemblee
                          </Typography>
                          <Title
                              variant="h1"
                              sx={{ color: "#fff"}}
                          >
                              Manage all your meetup activities & operations
                          </Title>
                          <Typography
                              variant="body2"
                              sx={{ fontSize: "18px", color: "#fff", my: 4 }}
                          >
                              Simple, reliable, transparent and secure.
                          </Typography>
                          <Button
                              sx={{backgroundColor: "#0F1B4C", color: "#fff", marginBottom: "1em"}}
                              variant="contained"
                              onClick={() => navigateToPage("/download")}
                          >
                              Download
                          </Button>
                      </Box>

                      {/*           <Box sx={{ flex: "1" }}>
            <img
              src={heroImg}
              alt="heroImg"
              style={{ maxWidth: "100%", marginBottom: "2rem" }}
            />
          </Box> */}
                  </CustomBox>
              </Container>
          </Box>
      </>
  );
};

export default Hero;
