import {Box, Container, styled, Typography} from "@mui/material";
import React from "react";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Signup from "./Signup";
import {NavLink} from "react-router-dom";

function LoginDialog(props) {
    const {onClose, open} = props;

    const handleClose = () => {
        onClose();
    };

    // const handleListItemClick = (value) => {
    //     onClose();
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 4,
                        marginBottom: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, backgroundColor: "#000"}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Remember me"
                        />
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            sx={{mt: 3, mb: 2, backgroundColor: "#495194"}}
                        >
                            Sign In
                        </Button>
                        <Grid container sx={{mb: 1}}>
                            <Grid item xs>
                                <NavLink to="/forgot-password"
                                         onClick={handleClose}
                                         variant="body2"
                                         style={{color: "#495194", fontWeight: "normal", textDecoration: "underline"}}
                                >
                                    Forgot password?
                                </NavLink>
                            </Grid>
                            <Grid item>
                                <NavLink to="/signup"
                                         variant="body2"
                                         style={{color: "#495194", fontWeight: "normal", textDecoration: "underline"}}
                                >
                                    {"Register"}
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
}

LoginDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default LoginDialog;
