import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";

const Faq = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    return (
        <Box sx={{ backgroundColor: "#F5FAFE", py: 10 }}>
            <Container>
                <PropertiesTextBox>
                    <Typography
                        sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold" }}
                    >
                        Coming soon!
                    </Typography>
                </PropertiesTextBox>
            </Container>
        </Box>
    );
};

export default Faq;
