import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import DownloadHero from "./DownloadHero";

const Download = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    return (
        <>
            <DownloadHero />
        </>
    );
};

export default Download;
