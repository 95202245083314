import {useState} from 'react';
import * as React from "react";
import {CircularProgress, TextField, Typography, Grid, Box, Paper, Button, Avatar} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PropTypes from 'prop-types';
import bg from "../media/stadium.jpg";

const PasswordReset = () => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
    };

    return (
        <Grid container component="main" sx={{height: '100vh'}}>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={1} square sx={{ backgroundColor: "#F5FAFE", pb: 8}}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{m: 1, backgroundColor: "#000"}}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset account password
                    </Typography>
                    <Typography component="body" variant="body2" sx={{mt: 2}}>
                        Enter a new password for your account.
                    </Typography>

                    <Box sx={{mt: 2, width: "100%"}}>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            autoComplete="password"
                            autoFocus
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="confirm-password"
                            label="Confirm Password"
                            name="confirm-password"
                            autoComplete="confirm-password"
                            autoFocus
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{mt: 3, mb: 2, backgroundColor: "#495194"}}
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            Submit
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(' + bg + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
        </Grid>
    );
};

PasswordReset.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default PasswordReset;
