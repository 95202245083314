import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const AboutUs = () => {
    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const Title = styled(Typography)(({ theme }) => ({
        fontSize: "64px",
        color: "#000336",
        fontWeight: "bold",
        margin: theme.spacing(4, 0, 4, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
        },
    }));

    return (
        <>
            <Box sx={{ backgroundColor: "#F5FAFE", py: 4 }}>
                <Container>
                    <PropertiesTextBox>
                        <Typography
                            sx={{ color: "#000339", fontSize: "35px", fontWeight: "bold", mb: "1em" }}
                        >
                            About Assemblee
                        </Typography>
                        <div>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Our App
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    More than 2000 assemblee groups in over 70 countries around the world use Assemblee to manage their operations and stay intouch
                                    with other assemblee groups.
                                    Assemblee is very simple to use with very rich features that will make your clubs run smoothly. Assemblee is available on all platforms (Web, Windows, Mac, iOs, Android)
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Our Mission
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Our mission is to enable all meetings and groups to manage their operations smoothly and with maximum transparency. We are committed to bringing state of the art solutions and innovations in order to meet your needs and expectations. We are uniting all the meetings and groups or communities across the globe.
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Our Team
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    Assemblee was founded by Etukeni Ndecha who had previously spent 14 years combined at BEET Technlogies Ltd.
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </PropertiesTextBox>
                </Container>
            </Box>
        </>
    );
};

export default AboutUs;

