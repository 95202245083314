import { Box, Container, styled, Typography } from "@mui/material";
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HelpCenter = () => {

    const PropertiesTextBox = styled(Box)(({ theme }) => ({
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    }));

    const CustomBox = styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
        },
    }));

    const Title = styled(Typography)(({ theme }) => ({
        fontSize: "64px",
        color: "#000336",
        fontWeight: "bold",
        margin: theme.spacing(4, 0, 4, 0),
        [theme.breakpoints.down("sm")]: {
            fontSize: "40px",
        },
    }));

    return (
        <>
            <Box sx={{ backgroundColor: "#F5FAFE", py: 4 }}>
                <Container>
                    <PropertiesTextBox>
                        <div>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Download and Installation
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Registration
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Troubleshooting
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Privacy
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4-content"
                                    id="panel4-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Security
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5-content"
                                    id="panel5-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        Setting Up Payments
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6-content"
                                    id="panel6-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        How to request your account information
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7-content"
                                    id="panel7-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        How to change your phone number
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel8-content"
                                    id="panel8-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        How to delete your account
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel9-content"
                                    id="panel9-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        About inactive account deletion
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel10-content"
                                    id="panel10-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        About temporarily banned accounts
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel11-content"
                                    id="panel11-header"
                                >
                                    <Typography
                                        sx={{ color: "#000339", fontSize: "20px", fontWeight: "bold" }}
                                    >
                                        About account bans
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </PropertiesTextBox>
                </Container>
            </Box>
        </>
    );
};

export default HelpCenter;

